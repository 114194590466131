import PropTypes from 'prop-types';

import cx from 'lib/cx';

const FormFieldGroup = ({ children, className }) => (
  <div className={cx('grid grid-cols-1 gap-y-6', className)}>{children}</div>
);

FormFieldGroup.propTypes = {
  children: PropTypes.node,
  className: PropTypes.node,
};

FormFieldGroup.defaultProps = {
  children: null,
  className: '',
};

export default FormFieldGroup;
